// 属地大屏
import request from '../request.js';
// 错误表述账号排行榜
export function misreAccountLeaderboard(data) {
    return request({
        url:"module-web/statisticsMisreData/misreAccountLeaderboard",
        method:"post",
        data
    })
}
// 错误表述词频
export function misreWordCloud(data={}) {
    return request({
        url:"module-web/statisticsMisreData/misreWordCloud",
        method: "post",
        data
    })
}
// 错误词排行榜
export function misreWordLeaderboard(data) {
    return request({
        url:"module-web/statisticsMisreData/misreWordLeaderboard",
        method:"post",
        data
    })
}
// 预警情况 --- 错误表述预警日期直方图
export function planMonitorDataWarn(data) {
    return request({
        url: "module-web/statisticsMisreData/planMonitorDataWarn",
        method: "post",
        data
    })
}
// 错误表述轮询情况
export function planMonitorDataCheck() {
    return request({
        url: "module-web/statisticsMisreData/planMonitorDataCheck",
        method: "get"
    })
}
