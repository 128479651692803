<template>
  <div class="content">
    <div class="top">
      <div class="sum flex-d">
        <span class="name">{{title.name}}</span>
        <span class="num">{{title.sum}}</span>
      </div>
      <img src="../../../assets/img/views/cu_tit.png" alt="">
    </div>
    <div class="main">
      <div class="item flex-b-c" v-for="(item,index) in list" :key="index">
        <img src="../../../assets/img/views/cu_name.png" alt="">
        <span class="name">{{item.name}}</span>
        <span class="num">{{item.value}}</span>
      </div>
    </div>
  </div>
</template>
  
<script>
import echartMixin from "@/mixins/echartMixin";
import { getAbnormalNum } from "@/api/index/index";
export default {
  mixins: [echartMixin],
  data() {
    return {
      list: [
      ],
      title: {
        name: '监测账号总数',
        sum: 0
      }
    }
  },
  created() {
    this.setInfo()
  },
  methods: {
    setInfo() {
      this.getInfo({
        APiFn: getAbnormalNum,
        data: {
          aggField: "body_type",
          dictField: "body_type",
        }
      });
    },
    getEcharts(ResData){
      this.list = ResData.filter(v => {
        return v.name !== '个人' && v.name !== '非国有企业' && v.name !== '境外机构'
      })
      this.title.sum = this.list.reduce((total, item) => total + item.value*1, 0);
    }
  }
}
</script>
  
<style lang="scss" scoped>
.content {
  padding: 0.3529rem 0.2824rem;
  .top {
    position: relative;
    margin-bottom: 0.3529rem;
    background: linear-gradient(
        -86deg,
        rgba(5, 5, 32,1),
        rgba(13, 142, 233, 0.3) 100%
      );
    img {
      position: absolute;
      top: -0.1176rem;
      left: 0;
    }
    .sum {
      width: 3.4118rem;
      height: 0.9294rem;
      
      margin-left: 0.5412rem;
      color: #fff;
      text-align: center;
      .name {
        font-size: 0.1647rem;
      }
      .num {
        font-size: 0.3765rem;
      }
    }
  }
  .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .item {
      width: 48%;
      padding: 0.0941rem 0.0941rem;
      font-size: 0.1647rem;
      color: #fff;
      background: linear-gradient(
        -86deg,
        rgba(5, 5, 32,1),
        rgba(13, 142, 233, 0.3) 100%
      );
      margin-bottom: 0.1882rem;
      span {
        display: inline-block;
        width: 40%;
      }
    }
  }
}
</style>