<template>
  <div class="displayBetween" style="height: 100%; width: 100%" id="centerBottom"></div>
</template>

<script>
import echartMixin from "@/mixins/echartMixin";
import { misreTimeDistribution } from '@/api/daping/index'
import myEchartFn from "@/utils/echarts/index";
export default {
  mixins: [echartMixin],
  data() {
    return {
      autoTime: null
    }
  },
  created() {
    this.setInfo();
  },
  // mounted() {
  //   let myChart = this.$echarts.init(document.getElementById('centerBottom'));
  //   let ResData = [];
  //   this.getEcharts(myChart, ResData)
  // },
  methods: {
    setInfo(period = 1) {
      this.getInfo({
        APiFn: misreTimeDistribution,
        data: { period },
        doName: "centerBottom",
      });
    },
    getEcharts(myChart, ResData) {
      console.log(ResData, '折线');
      ResData = ResData.map(v => {
        return {
          name: v.date,
          value: v.total
        }
      })
      console.log(ResData, '折线1');
      if (this.autoTime) {
        clearInterval(this.autoTime)
        this.autoTime = null
      }
      let { dataX, dataArr, dataX2, dataArr2 } = myEchartFn.getSwiperItem(
        ResData,
        6
      );
      let option = {
        tooltip: {
          // trigger: 'axis',
          // axisPointer: {
          //   type: 'cross',
          //   animation: false,
          //   label: {
          //     backgroundColor: '#505765'
          //   },
          //   lineStyle: {
          //     type: 'dashed'
          //   }
          // }
          show: true,
          trigger: 'axis',
          borderColor: "rgba(147, 235, 248, 0)",
          borderWidth: 1,
          padding: [10, 15, 10, 15],
          confine: true,
          backgroundColor: 'rgba(0,0,0,.9)',
          textStyle: {
            color: "#FFF",
          },
        },
        grid: {
          left: "5%",
          top: "10%",
          right: "0%",
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          data: dataX,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#BDD8FB", //X轴文字颜色
              fontSize: 12,
            },
          },
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#163a5f",
              width: 2,
            },
          },
          axisTick: {
            show: false, //隐藏X轴刻度
            alignWithLabel: true,
          },
        },
        yAxis: {

          splitLine: {
            show: false
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.15)",
              type: 'dashed', // dotted 虚线
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //隐藏X轴轴线
            lineStyle: {
              color: "#163a5f",
              width: 1,
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#BDD8FB",
              fontSize: 12,
            },
          },
        },
        series: [{
          type: 'line',
          data: dataArr,
          smooth: true,
          lineStyle: {
            color: '#02D9D9'
          },
          itemStyle: {
            // 设置线条上点的颜色（和图例的颜色）
            normal: {
              color: '#02D9D9',
            },
          },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#68e3e5"
                    },
                    {
                      offset: 1,
                      color: "rgba(24, 68, 121,0)"
                    }
                  ],
                  false
                )
              }
            }
          }]
      };
      myChart.setOption(option);
      this.autoTime = setInterval(() => {
        myEchartFn.autoMove(
          { dataX, dataArr, dataX2, dataArr2 },
          option,
          myChart,
          this.autoTime,
        );
      }, 3000);
    },
  },
};
</script>

<style>
</style>