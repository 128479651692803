<template>
  <div class="displayBetween" style="height: 100%; width: 100%" id="RightTwo"></div>
</template>
  
<script>
import echartMixin from "@/mixins/echartMixin";
import myEchartFn from "@/utils/echarts/index";
import { zhuzi } from '@/utils/echarts/shudi/zhuzi'
import { planMonitorDataWarn } from '@/api/cwdaping/index'
export default {
  mixins: [echartMixin],
  data() {
    return {
      autoTime: null
    }
  },
  created() {
    this.setInfo()
  },
  // mounted() {
  //   let myChart = this.$echarts.init(document.getElementById('RightTwo'));
  //   let ResData = [{
  //     name: '2022-9',
  //     value: 200
  //   }, {
  //     name: '2022-10',
  //     value: 10
  //   }, {
  //     name: '2022-11',
  //     value: 30
  //   }, {
  //     name: '2022-12',
  //     value: 40
  //   }];
  //   this.getEcharts(myChart, ResData)
  // },
  methods: {
    setInfo(period = 1) {
      this.getInfo({
        APiFn: planMonitorDataWarn,
        data: { period },
        doName: "RightTwo"
      })
    },
    getEcharts(myChart, ResData) {
      console.log(ResData, '预警');
      ResData = ResData.map(v => {
        return {
          name: v.date,
          value: v.total
        }
      })
      if (this.autoTime) {
        clearInterval(this.autoTime)
        this.autoTime = null
      }
      let { dataX, dataArr, dataX2, dataArr2 } = myEchartFn.getSwiperItem(
        ResData,
        4
      );
      let option = zhuzi(dataX, dataArr);
      myChart.setOption(option);
      this.autoTime = setInterval(() => {
        myEchartFn.autoMove(
          { dataX, dataArr, dataX2, dataArr2 },
          option,
          myChart,
          this.autoTime,
        );
      }, 3000);
    }
  }
}
</script>
  
<style lang="scss" scoped>
</style>