import { getMyChartok, kongOption } from "@/utils/echarts";
let echartMixin = {
    methods: {
        async getInfo({ APiFn, data, doName }) {
            let Res =data? await APiFn(data) : await APiFn();
            let myChart = doName? getMyChartok(doName) : null;
            let ResData = Res?.data?.data || []
            if (!ResData || ResData.length == 0) {
                if(myChart) myChart.setOption(kongOption());  
                return;
            }
            let { getEcharts} = this
            myChart ? getEcharts(myChart, ResData || []) : getEcharts(ResData || [])
        },
    },
}
export default echartMixin